import {
    AbsoluteFill,
    interpolate,
    useCurrentFrame,
    useVideoConfig,
} from "remotion";
import { loadFont } from "@remotion/google-fonts/Inter";
import { z } from "zod";

export const FadeInHelloWorldId = "fade-in-hello-world";

export const FadeInHelloWorldSchema = z.object({
    text: z.string(),
});

export type FadeInHelloWorld = z.infer<typeof FadeInHelloWorldSchema>;

export function FadeInHelloWorld({ text }: FadeInHelloWorld) {
    const frame = useCurrentFrame();
    const { durationInFrames } = useVideoConfig();
    const inter = loadFont();

    const textOpacity = interpolate(
        frame,
        [durationInFrames - 50, durationInFrames - 30],
        [0, 1],
        {
            extrapolateRight: "clamp",
        },
    );

    return (
        <AbsoluteFill
            className="items-center justify-center flex flex-col font-semibold"
            style={{
                backgroundColor: "#f4f4f4",
                color: "#1a1a1a",
                fontFamily: inter.fontFamily,
                fontWeight: 600,
                fontSize: "10rem",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ opacity: textOpacity }}>Hello {text}</div>
        </AbsoluteFill>
    );
}
