import { z } from "zod";
import { AbsoluteFill, Img, staticFile } from "remotion";
import { loadFont } from "@remotion/google-fonts/Inter";
import React from "react";

export const AssetVideoSchema = z.object({
    text: z.string(),
    // Blob is only needed for preview of video, when video is rendered all blobs are passed as links to r2 bucket on cloudflare
    file: z.union([z.instanceof(Blob), z.string().url()]),
});

export type AssetVideoProps = z.infer<typeof AssetVideoSchema>;

export const AssetVideoId = "asset-video";

export function AssetVideo({ text, file }: AssetVideoProps) {
    const inter = loadFont();

    const getIsURL = (s: unknown): s is string => {
        if (typeof s !== "string") {
            return false;
        }
        try {
            new URL(s);
            return true;
        } catch (_) {
            return false;
        }
    };

    const getFileIsEmpty = (file: Blob | string) => {
        if (getIsURL(file)) {
            return false;
        }
        return file.size === 0 && file.type.trim() === "";
    };

    const isURL = getIsURL(file);

    const fileIsEmpty = getFileIsEmpty(file);

    const imageStyle: React.CSSProperties = {
        width: 256,
        height: 256,
        borderRadius: 42,
    };

    return (
        <AbsoluteFill
            className="items-center justify-center flex flex-col font-semibold"
            style={{
                backgroundColor: "#f4f4f4",
                color: "#1a1a1a",
                fontFamily: inter.fontFamily,
                fontWeight: 600,
                fontSize: "10rem",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {!fileIsEmpty ? (
                <Img
                    style={imageStyle}
                    src={isURL ? file : URL.createObjectURL(file)}
                />
            ) : (
                <Img style={imageStyle} src={staticFile("favicon.ico")} />
            )}
            <div>Hello {text}</div>
        </AbsoluteFill>
    );
}
