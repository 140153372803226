import { Composition } from "remotion";
import {
    FadeInHelloWorld,
    FadeInHelloWorldId,
    FadeInHelloWorldSchema,
} from "./FadeInHelloWorld";
import { AssetVideo, AssetVideoId, AssetVideoSchema } from "./AssetVideo";
import {
    BackgroundColor,
    BackgroundColorId,
    BackgroundColorSchema,
} from "./BackgroundColor";
import { defaultVideoData, VideoConfigs } from "../app/utils/video";

export function RemotionRoot() {
    return (
        <>
            <Composition
                id={FadeInHelloWorldId}
                component={FadeInHelloWorld}
                defaultProps={defaultVideoData[FadeInHelloWorldId]}
                schema={FadeInHelloWorldSchema}
                durationInFrames={
                    VideoConfigs[FadeInHelloWorldId].durationInFrames
                }
                fps={VideoConfigs[FadeInHelloWorldId].fps}
                width={VideoConfigs[FadeInHelloWorldId].compositionWidth}
                height={VideoConfigs[FadeInHelloWorldId].compositionHeight}
            />
            <Composition
                id={AssetVideoId}
                component={AssetVideo}
                defaultProps={defaultVideoData[AssetVideoId]}
                schema={AssetVideoSchema}
                durationInFrames={VideoConfigs[AssetVideoId].durationInFrames}
                fps={VideoConfigs[AssetVideoId].fps}
                width={VideoConfigs[AssetVideoId].compositionWidth}
                height={VideoConfigs[AssetVideoId].compositionHeight}
            />
            <Composition
                id={BackgroundColorId}
                component={BackgroundColor}
                defaultProps={defaultVideoData[BackgroundColorId]}
                schema={BackgroundColorSchema}
                durationInFrames={
                    VideoConfigs[BackgroundColorId].durationInFrames
                }
                fps={VideoConfigs[BackgroundColorId].fps}
                width={VideoConfigs[BackgroundColorId].compositionWidth}
                height={VideoConfigs[BackgroundColorId].compositionHeight}
            />
        </>
    );
}
