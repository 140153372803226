import {
    FadeInHelloWorld,
    FadeInHelloWorldId,
    FadeInHelloWorldSchema,
} from "../../video/FadeInHelloWorld";
import {
    AssetVideo,
    AssetVideoId,
    AssetVideoSchema,
} from "../../video/AssetVideo";
import {
    BackgroundColor,
    BackgroundColorId,
    BackgroundColorProps,
    BackgroundColorSchema,
} from "../../video/BackgroundColor";

export const VideoSchemas = {
    [FadeInHelloWorldId]: FadeInHelloWorldSchema,
    [AssetVideoId]: AssetVideoSchema,
    [BackgroundColorId]: BackgroundColorSchema,
} as const;

type VideoConfig = {
    durationInFrames: number;
    compositionWidth: number;
    compositionHeight: number;
    fps: number;
    displayName: string;
    description: string;
    thumbnailFrame: number;
};

export const VideoIds: VideoSlug[] = [
    FadeInHelloWorldId,
    AssetVideoId,
    BackgroundColorId,
];

export const BaseConfig: Omit<VideoConfig, "displayName" | "description"> = {
    durationInFrames: 60,
    fps: 30,
    compositionWidth: 2560,
    compositionHeight: 1440,
    thumbnailFrame: 30,
};

export const VideoConfigs: Record<VideoSlug, VideoConfig> = {
    [FadeInHelloWorldId]: {
        ...BaseConfig,
        displayName: "Fade in example",
        description:
            "Simple example of video that can be rendered with fade in text in the beginning",
    },
    [AssetVideoId]: {
        ...BaseConfig,
        displayName: "Asset example",
        description:
            "Example of video with logo of your choosing, you can upload anything that you want.",
    },
    [BackgroundColorId]: {
        ...BaseConfig,
        displayName: "Background color example",
        description:
            "Example of video customizable background color, you can adjust it to whatever color you want using color picker",
    },
};
export const VideoComponents = {
    [FadeInHelloWorldId]: FadeInHelloWorld,
    [AssetVideoId]: AssetVideo,
    [BackgroundColorId]: BackgroundColor,
};
export type VideoSlug = keyof typeof VideoSchemas;

const BackgroundColorInitialProps: BackgroundColorProps = {
    text: "World",
    "initial background color": "#1e2836",
    "final background color": "#112036"
}

export const defaultVideoData: Record<VideoSlug, any> = {
    [FadeInHelloWorldId]: { text: "World" },
    [AssetVideoId]: { text: "World", file: new Blob() },
    [BackgroundColorId]: BackgroundColorInitialProps,
};

export function isVideoSlug(slug: string): slug is VideoSlug {
    return slug in VideoSchemas;
}
