import {
    AbsoluteFill,
    interpolate,
    interpolateColors,
    useCurrentFrame,
    useVideoConfig,
} from "remotion";
import { loadFont } from "@remotion/google-fonts/Inter";
import { z } from "zod";
import { color } from "./validation";

export const BackgroundColorId = "background-color";

export const BackgroundColorSchema = z.object({
    text: z.string(),
    "initial background color": color,
    "final background color": color,
});

export type BackgroundColorProps = z.infer<typeof BackgroundColorSchema>;

export function BackgroundColor(props: BackgroundColorProps) {
    const frame = useCurrentFrame();
    const { durationInFrames } = useVideoConfig();
    const inter = loadFont();

    const textOpacity = interpolate(
        frame,
        [durationInFrames - 50, durationInFrames - 30],
        [0, 1],
        {
            extrapolateRight: "clamp",
        },
    );

    const bgColor = interpolateColors(
        frame,
        [0, 30],
        [props["initial background color"], props["final background color"]],
    );

    return (
        <AbsoluteFill
            className="items-center justify-center flex flex-col font-semibold"
            style={{
                backgroundColor: bgColor,
                color: "#1a1a1a",
                fontFamily: inter.fontFamily,
                fontWeight: 600,
                fontSize: "10rem",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ opacity: textOpacity, color: "white" }}>
                Hello {props.text}
            </div>
        </AbsoluteFill>
    );
}
